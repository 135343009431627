import React, { useState } from "react"
import {
  Overlay,
  Popup,
  ImageContainer,
  ContentContainer,
  CloseButton,
  Title,
  SubmitButton,
  NoDiscountLink,
  Paragraf,
} from "./popupElements"

const PromoPopupWithImage = () => {
  const [isVisible, setIsVisible] = useState(true)

  const closePopup = () => {
    setIsVisible(false)
  }

  const handleButtonClick = (event, shouldNavigate) => {
    if (shouldNavigate) {
      const section = document.querySelector("#contact")
      if (section) section.scrollIntoView({ behavior: "smooth" })
    }
    closePopup()
  }

  if (!isVisible) return null

  return (
    <Overlay>
      <Popup>
        <ImageContainer />
        <ContentContainer>
          <CloseButton onClick={closePopup} aria-label="Close Popup">
            &times;
          </CloseButton>
          <Title>RUSZYŁA PROMOCJA</Title>
          <Paragraf>DO KOŃCA LUTEGO DOM W CENIE 699 000 ZŁ!!!</Paragraf>
          <SubmitButton
            href="#contact"
            onClick={(event) => handleButtonClick(event, true)}
          >
            Skorzystaj z promocji
          </SubmitButton>
          <NoDiscountLink
            href="#"
            onClick={(event) => handleButtonClick(event, false)}
          >
            Nie, dziękuję
          </NoDiscountLink>
        </ContentContainer>
      </Popup>
    </Overlay>
  )
}

export default PromoPopupWithImage
